import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1>Hi people</h1>
    <p>Welcome to your new site.</p>
    <p>We are building a new experience to find and connect wedding halls in Los Angeles area. <br/>
    If you have a venue that you would like to list on our website please <Link to="/contact/">contact me</Link> to be listed.
    </p>
  </Layout>
)

export default IndexPage
